import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ListItemIcon from '@mui/material/ListItemIcon';

import InfoIcon from '@mui/icons-material/Info';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PlaceIcon from '@mui/icons-material/Place';


function User(props) {
  if (props.user) {
    return (<>
      <IconButton sx={{ padding: '5px', marginLeft: '5px' }} onClick={props.handleUserMenuClick}>
        <AccountCircleIcon color="icon"/>
      </IconButton>
    </>)
  }
  return <></>;
}

function MenuItemWithIcon(props) {
  return (
    <MenuItem>
      <Link sx={{ color: '#46505A' }} href={props.href} underline="none">
        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
          <ListItemIcon>{props.icon}</ListItemIcon>{props.text}
        </Box>
      </Link>
    </MenuItem>
  );
}

function Sources(props) {
  if (props.user) {
    return <Link sx={{ color: '#46505A', fontWeight: '500', margin: '10px' }} href="/sources" underline="none">Sources</Link>
  }
  return <></>;
}

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      userAnchorEl: null,
      moreAnchorEl: null,
      open: false,
      userMenuOpen: false,
      moreMenuOpen: false
    }
  }

  render() {
    var userQuery = gql`
    {
      user {
        email
        name
      }
    }`
    return (
      <Query query={userQuery}>
        {({ data, loading }) => (
          <>
            <Box sx={{ display: {xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex'}, flexDirection: 'row', justifyContent: 'space-between', paddingTop: '20px', paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px', borderBottom: '1px solid #EEEEEE' }}>
              <Typography>
                <Link sx={{ color: '#46505A', fontWeight: '500', margin: '10px' }} href="/" underline="none">Home</Link>
                <Link sx={{ color: '#46505A', fontWeight: '500', margin: '10px' }} href="/search" underline="none">Search</Link>
                <Link sx={{ color: '#46505A', fontWeight: '500', margin: '10px' }} href="/posts" underline="none">Posts</Link>
                <Link sx={{ color: '#46505A', fontWeight: '500', margin: '10px' }} href="/collections" underline="none">Collections</Link>
                <Link sx={{ color: '#46505A', fontWeight: '500', margin: '10px' }} href="/people" underline="none">Family Tree</Link>
                <IconButton sx={{ padding: '5px' }} onClick={this.handleMoreMenuClick}>
                  <MoreVertIcon/>
                </IconButton>
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ fontSize: '14px',  alignContent: 'center' }}>376,936 records searchable</Typography>
                <Tooltip title="Contribute a photo">
                  <IconButton sx={{ padding: '5px', marginLeft: '10px', backgroundColor: 'transparent' }} onClick={this.handleUploadPhotoClick}>
                    <AddAPhotoIcon color="icon"/>
                  </IconButton>
                </Tooltip>
                <User user={loading ? null : data.user} handleUserMenuClick={this.handleUserMenuClick}/>
              </Box>
            </Box>
            <Box sx={{ display: {xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none'}, flexDirection: 'row', justifyContent: 'space-between', paddingTop: '20px', paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px', borderBottom: '1px solid #EEEEEE' }}>
              <IconButton sx={{ padding: '5px' }} onClick={this.handleMenuClick}>
                <MenuIcon/>
              </IconButton>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ fontSize: '14px', alignContent: 'center' }}>376,936 records searchable</Typography>
                <Tooltip title="Contribute a photo">
                  <IconButton sx={{ padding: '5px', marginLeft: '5px' }} onClick={this.handleUploadPhotoClick}>
                    <AddAPhotoIcon color="icon"/>
                  </IconButton>
                </Tooltip>
                <User user={loading ? null : data.user} handleUserMenuClick={this.handleUserMenuClick}/>
              </Box>
            </Box>
            <Menu anchorEl={this.state.anchorEl} open={this.state.open} onClose={this.handleClose}>
              <MenuItem><Link sx={{ color: '#46505A' }} href="/" underline="none">Home</Link></MenuItem>
              <MenuItem><Link sx={{ color: '#46505A' }} href="/search" underline="none">Search</Link></MenuItem>
              <MenuItem><Link sx={{ color: '#46505A' }} href="/posts" underline="none">Posts</Link></MenuItem>
              <MenuItem><Link sx={{ color: '#46505A' }} href="/collections" underline="none">Collections</Link></MenuItem>
              <MenuItem><Link sx={{ color: '#46505A' }} href="/people" underline="none">Family Tree</Link></MenuItem>
              <MenuItem><Link sx={{ color: '#46505A' }} href="/periodicals" underline="none">Periodicals</Link></MenuItem>
              <MenuItem><Link sx={{ color: '#46505A' }} href="/places" underline="none">Places</Link></MenuItem>
              <MenuItem sx={{ display: (!loading && data.user ? "block" : "none")}}><Link sx={{ color: '#46505A' }} href="/sources" underline="none">Sources</Link></MenuItem>
              <MenuItem><Link sx={{ color: '#46505A' }} href="/about" underline="none">About</Link></MenuItem>
            </Menu>
            <Menu anchorEl={this.state.moreAnchorEl} open={this.state.moreMenuOpen} onClose={this.handleMoreMenuClose}>
              <MenuItemWithIcon text="Periodicals" href="/periodicals" icon={<NewspaperIcon/>}/>
              <MenuItemWithIcon text="Places" href="/places" icon={<PlaceIcon/>}/>
              <MenuItem sx={{ display: (!loading && data.user ? "block" : "none")}}><Link sx={{ color: '#46505A' }} href="/sources" underline="none">Sources</Link></MenuItem>
              <MenuItemWithIcon text="About" href="/about" icon={<InfoIcon/>}/>
            </Menu>
            <Menu anchorEl={this.state.userAnchorEl} open={this.state.userMenuOpen} onClose={this.handleUserMenuClose}>
              <MenuItem><Link sx={{ color: '#46505A' }} onClick={() => {
                fetch('/logout', { method: 'GET' }).then(() => window.location.reload());}} underline="none">Logout</Link></MenuItem>
            </Menu>
          </>
        )}
      </Query>
    );
  }

  handleUploadPhotoClick = event => {
    document.location.href = "/images/new";
  }

  handleMenuClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget})
  }

  handleClose = event => {
    this.setState({open: false, anchorEl: null})
  }

  handleUserMenuClick = event => {
    this.setState({userMenuOpen: true, userAnchorEl: event.currentTarget})
  }

  handleUserMenuClose = event => {
    this.setState({userMenuOpen: false, userAnchorEl: null})
  }

  handleMoreMenuClick = event => {
    this.setState({moreMenuOpen: true, moreAnchorEl: event.currentTarget})
  }

  handleMoreMenuClose = event => {
    this.setState({moreMenuOpen: false, moreAnchorEl: null})
  }

}
