import React from 'react';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Header from './Header';
import RecordsSearch from './Search/RecordsSearch';
import WhatsNew from './WhatsNew';
import { getNodeName } from '../utils/NodeUtils';

import landingPageImage from '../images/annapolis/landingPage.jpg';

export default function LandingPage(props) {
  /*const [landingPageImage, setLandingPageImage] = useState(null);
  const [strings, setStrings] = useState({"landingPageHeading": "Find your ancestors"});
  useEffect(() => {
    async function loadNodeFiles() {
      let nodeName = getNodeName();
      const landingPageImage = await import('../images/' + nodeName + '/landingPage.jpg');
      setLandingPageImage(landingPageImage.default);
      const strings = await import('../strings/' + nodeName + '.json');
      setStrings(strings);
    }
    loadNodeFiles();
  }, [strings, landingPageImage]);*/

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Header/>
      <WhatsNew/>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h2" sx={{ marginLeft: '20px', fontWeight: 500 }}>{"Find your Annapolis County ancestors"}</Typography>
          <Typography variant="h5" sx={{ marginLeft: '20px', fontWeight: 500, paddingTop: '15px', paddingLeft: '15px' }}>{"[and Digby County too]"}</Typography>
        </Box>
        <img className='landing-image' src={landingPageImage}/>
      </Box>
      <RecordsSearch
        surname=""
        givenNames=""
        datasets=""
        soundex={false}
      />
    </Box>
  );
}